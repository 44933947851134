var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permissions-table"},[_c('permissions-dialog',{attrs:{"item":_vm.activeItem,"role":_vm.selected_group},on:{"update":_vm.handleUpdatePermission},model:{value:(_vm.edit_dialog),callback:function ($$v) {_vm.edit_dialog=$$v},expression:"edit_dialog"}}),_c('VueTable',{key:_vm.componentKey,attrs:{"items":_vm.items,"headers":_vm.headers,"show-row-actions":true,"icon":"widgets","title":"Permissions","no-more-data":!_vm.has_more_items,"show-select":false,"loading":_vm.loading_items,"has-footer":false},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(){return [_c('v-select',{staticClass:"col-md-3 permissions-selection",attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Select Group","items":_vm.groups},model:{value:(_vm.selected_group),callback:function ($$v) {_vm.selected_group=$$v},expression:"selected_group"}})]},proxy:true},{key:"row-slot",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(_vm._f("removeSlug")(_vm.snakeCaseToNormal(item.name)))))]),_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.description)))]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":item.slug.view ? 'primary' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-check-outline ")])]}}],null,true)},[_c('span',[_vm._v("Can view")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":item.slug.create ? 'primary' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple-plus-outline ")])]}}],null,true)},[_c('span',[_vm._v("Can create")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":item.slug.update ? 'primary' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v("Can update")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":item.slug.delete ? 'primary' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple-remove-outline ")])]}}],null,true)},[_c('span',[_vm._v("Can delete")])])],1),(item.company_id !== 0)?_c('Actions',{attrs:{"item":item,"has-delete":false,"has-view":false,"permissions":_vm.$_permissions.get('permissions')},on:{"edit":function($event){return _vm.action_edit_dialog(true, item)}}}):_c('td',[_vm._v("Forbidden")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }