import _ from 'lodash'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import makeRequestTo from '@/services/makeRequestTo'
import { api_to } from '../../api'
import { mapGetters } from 'vuex'
export default {
    name: 'PermissionDialog',
    components: { CustomDialog },
    props: {
        value: { type: Boolean, default: false },
        item: { type: Object },
        role: { type: Number }
    },
    computed: {
        ...mapGetters(['user']),
        isEditDialog() {
            return this.item ? true : false
        },
        dialogTitle() {
            return this.isEditDialog ? 'Update Permission' : 'New permission'
        }
    },
    data: () => ({
        open: false,
        description: null,
        permission_name: null,
        selected_permission: null,
        selected_group: null,
        permissions: [],
        group_items: [],
        loading_permissions: false,
        slug: {
            create: false,
            view: false,
            update: false,
            delete: false
        }
    }),

    watch: {
        value: {
            handler(new_val) {
                this.open = new_val
                new_val && this.fill_group_items()
            },
            immediate: true
        },
        open(new_val) {
            this.$emit('input', new_val)
        },
        role(new_val) {
            this.selected_group = new_val
        },
        item: {
            handler(new_val) {
                if (new_val) {
                    this.update_fields(new_val)
                }
            },
            deep: true,
            immediate: true
        },
        'slug.create' (val) {
            if (val) {
                this.slug.view = true
            } else {
                this.slug.update = false
            }
        },
        'slug.update' (val) {
            if (val) {
                this.slug.create = true
            } else {
                this.slug.delete = false
            }
        },
        'slug.delete' (val) {
            if (val) {
                this.slug.update = true
            }
        },
        'slug.view' (val) {
            if (!val) {
                this.slug = {
                    create: false,
                    view: false,
                    update: false,
                    delete: false
                }
            }
        }
    },

    created() {
        this.loading_permissions = true
        api_to
            .get_default_permissions()
            .then(({ data }) => (this.permissions = data))
            .finally(() => (this.loading_permissions = false))
    },

    methods: {
        fill_group_items() {
            this.loading = false
            makeRequestTo
                .get_all_groups()
                .then(({ data }) => (this.group_items = data))
                .finally(() => (this.loading = false))
        },
        cancel() {
            this.open = false
        },
        save() {
            let fields_to_save = {
                permission_id: this.selected_permission,
                group: this.selected_group,
                description: this.description,
                slug: this.slug
            }
            this.$emit(this.isEditDialog ? 'update' : 'save', fields_to_save)
        },

        update_fields(fields) {
            const new_fields = _.cloneDeep(fields)
            this.description = new_fields.description
            this.selected_permission = new_fields.id
            this.slug = new_fields.slug
            this.permission_name = new_fields.name
        },

        clear_and_close() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.cancel() //close the modal
        },

        normalize(string) {
            return string.replace(`.${this.user.company.id}-`, ' : ')
        }
    }
}