import { render, staticRenderFns } from "./Permissions.vue?vue&type=template&id=603b6380&scoped=true&"
import script from "./Permissions.js?vue&type=script&lang=js&"
export * from "./Permissions.js?vue&type=script&lang=js&"
import style0 from "./Permissions.scss?vue&type=style&index=0&id=603b6380&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "603b6380",
  null
  
)

export default component.exports